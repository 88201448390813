import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {withLocalization} from '../../localizationContext';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {DateTime} from "luxon";

import PurifiedTypo from "../shared/parts/PurifiedTypo";
import StyledButton from "../shared/parts/StyledButton";
import Typography from "../shared/parts/Typography";

class Meeting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      as_page: !!props.as_page,
      user: props.user || props.login,
      meeting: {
        name: 'placeholder'
      }
    };
  }

  componentDidMount() {
    this.props.helpers.meetings.fetch_all({
      locale: this.props.i18n.language,
      login: this.props.login,
      callback: (r) => {
        this.setState({meeting: r});
      },
      parameters: {
        vennr: this.state.user.vennoot_number,
        birth_date: this.state.user.birth_date,
        fields: [
          'id', 'name', 'field_intro', 'is_mandatory', 'field_location', 'meeting_day', 'last_day', 'last_day_vote', 'attendance', 'field_backstage_name'
        ]
      }
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const {t} = other;

    let has_voted = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.online && this.state.meeting.attendance.online.id);
    let is_attending = (this.state.meeting && this.state.meeting.attendance && this.state.meeting.attendance.physical && this.state.meeting.attendance.physical.enabled);

    return !this.state.meeting.id ? (<span></span>) : (<Container sx={{paddingLeft: 0, paddingRight: 0}}>
      <Grid container spacing="2" style={{marginBottom: this.props.theme.spacing(4)}}>
        <Grid item xs={12}>
          {!this.state.open && <Box className={classes.orange_box_dense} onClick={() => {this.setState({open: true});}}>
            <Typography variant="h3">{this.state.meeting.field_backstage_name}</Typography>
            <ExpandMoreIcon sx={{position: 'absolute', top: 0, right: {xs: -24, md: 0}, marginRight: 3, marginTop: 0.5, width: '1.25em', height: '1.25em'}} />
          </Box>}
          {(!!this.state.as_page || !!this.state.open) && <Box className={!!this.state.as_page ? classes.relative_box : classes.orange_box}>
            <Typography variant="h3" align="center">{this.state.meeting.field_backstage_name}</Typography>
            {!this.state.as_page && <ExpandLessIcon sx={{position: 'absolute', top: 0, right: {xs: -24, md: 0}, marginRight: 3, marginTop: 2, width: '1.25em', height: '1.25em', cursor: 'pointer'}} onClick={() => {this.setState({open: false});}} />}
            <Container sx={{marginTop: 2}}>
              {!has_voted && !is_attending && <Grid container spacing="2">
                <Grid item xs={12} md={6} sx={{paddingRight: {xs: 0, md: 2}}}>
                  <Typography variant="h4">{t("meetings.notification.titles.invitation").replace(/<[^>]*>?/gm, '')}</Typography>
                  <PurifiedTypo text={this.state.meeting.field_intro} sx={{"& a": {color: `${this.props.theme.palette.primary.main} !important`}}} />
                </Grid>
                <Grid item xs={12} md={6} sx={{paddingLeft: {xs: 0, md: 2}}}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t("meetings.notification.titles.attendance").replace(/<[^>]*>?/gm, '')}</Typography>
                    <PurifiedTypo text={t("meetings.notification.texts.attendance", {attendance: (this.state.meeting.is_mandatory ? t("meetings.attendance_yes").replace(/<[^>]*>?/gm, '') : t("meetings.attendance_no").replace(/<[^>]*>?/gm, ''))})} />
                  </Grid>
                  <Grid item xs={12}>
                    <PurifiedTypo text={t("meetings.notification.texts.online_voting", {
                      last_voting_day: DateTime.fromISO(this.state.meeting.last_day_vote).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE)
                    })} />
                  </Grid>
                  <Grid item xs={12}>
                    {DateTime.fromISO(this.state.meeting.last_day_vote) >= DateTime.now() && <StyledButton text={t("meetings.notification.cta.online_voting").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.props.history.push({pathname: '/meeting/voting', state: {user: this.state.user, meeting: this.state.meeting}}); window.scrollTo(0, 0);
                    }} />}
                    {DateTime.fromISO(this.state.meeting.last_day_vote) < DateTime.now() && <Typography className={classes.grey_error} variant="h5">{t("meetings.notification.error.after_voting_last_day").replace(/<[^>]*>?/gm, '')}</Typography>}
                  </Grid>
                  <Grid item xs={12}>
                    <PurifiedTypo text={t("meetings.notification.texts.fysical_attendance", {
                      location: this.state.meeting.field_location,
                      last_day: DateTime.fromISO(this.state.meeting.last_day).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE),
                      meeting_day: DateTime.fromISO(this.state.meeting.meeting_day).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE)
                    })} />
                  </Grid>
                  <Grid item xs={12}>
                    {DateTime.fromISO(this.state.meeting.last_day) >= DateTime.now() && <StyledButton text={t("meetings.notification.cta.confirm_physical").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                      this.props.history.push({pathname: '/meeting/attend', state: {user: this.state.user, meeting: this.state.meeting}}); window.scrollTo(0, 0);
                    }} />}
                    {DateTime.fromISO(this.state.meeting.last_day) < DateTime.now() && <Typography className={classes.grey_error} variant="h5">{t("meetings.notification.error.after_physical_last_day").replace(/<[^>]*>?/gm, '')}</Typography>}
                  </Grid>
                </Grid>
              </Grid>}

              {!has_voted && !!is_attending && <Grid container spacing="2">
                <Grid item xs={12} md={4} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                <Grid item xs={12} md={4}>
                  <PurifiedTypo text={t("meetings.notification.attending.intro", {meeting_day: DateTime.fromISO(this.state.meeting.meeting_day).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE), location: this.state.meeting.field_location})} />
                  <PurifiedTypo text={this.state.meeting.field_intro} />
                  <PurifiedTypo text={t("meetings.notification.attending.outro", {meeting_day: DateTime.fromISO(this.state.meeting.meeting_day).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE), location: this.state.meeting.field_location})} />
                </Grid>
                <Grid item xs={12} md={4} align="right" style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
                  {this.state.meeting.attendance.physical.confirmation && <StyledButton text={t("meetings.notification.cta.physical_download_confirmation").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                    window.open(this.state.meeting.attendance.physical.confirmation, "_BLANK");
                  }} style={{alignSelf: 'end'}} />}
                </Grid>
              </Grid>}

              {!!has_voted && !is_attending && <Grid container spacing="2">
                <Grid item xs={12} md={4} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
                <Grid item xs={12} md={4}>
                  <PurifiedTypo text={t("meetings.notification.online.intro", {meeting_day: DateTime.fromISO(this.state.meeting.meeting_day).setLocale(this.props.i18n.language).toLocaleString(DateTime.DATE_HUGE), location: this.state.meeting.field_location})} />
                </Grid>
                <Grid item xs={12} md={4} align="right" style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
                  {this.state.meeting.attendance.online.confirmation && <StyledButton text={t("meetings.notification.cta.online_download_confirmation").replace(/<[^>]*>?/gm, '')} theme={this.props.theme} onClick={(e) => {
                    window.open(this.state.meeting.attendance.online.confirmation, "_BLANK");
                  }} style={{alignSelf: 'end'}} />}
                </Grid>
              </Grid>}
            </Container>
          </Box>}
        </Grid>
      </Grid>
    </Container>);
  }
}

Meeting.propTypes = {
  t: PropTypes.func.isRequired
};

const styles = theme => ({
  relative_box: {
    position: 'relative'
  },
  orange_box: {
    border: `4px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(3),
    position: 'relative'
  },
  orange_box_dense: {
    border: `4px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer'
  },
  grey_error: {
    fontStyle: 'italic',
    opacity: 0.7
  }
});

export default withLocalization(withTranslation('translations')(withStyles(styles, {withTheme: true})(withRouter(Meeting))));
